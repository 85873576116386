import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Activity, Calendar, Package, Home, CheckSquare } from "react-feather";
import NavBarItem from "./NavBarItem";
import { Box, Divider, Drawer, Hidden, List } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalMall } from "@mui/icons-material";
import { ReactComponent as Icon } from "../../assets/img/logo-pendol.svg";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		height: "calc(100%)",
	},
	menu: {
		backgroundColor: theme.palette.background.main,
	},
	logo: {
		"& .cls-1": {
			fill: theme.palette.background.color,
		},
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();
	const user = JSON.parse(localStorage.getItem("user")) || "";

	const items =
		String(user.role_id) === "1"
			? [
					{
						href: "/admin/",
						icon: Home,
						title: "Inici",
					},
					{},
					{
						href: "/admin/activitats",
						icon: Activity,
						title: "Activitats",
					},
					{
						href: "/admin/materials",
						icon: Package,
						title: "Materials",
					},
					{},
					{
						href: "/admin/calendari",
						icon: Calendar,
						title: "Calendari",
					},
					{
						href: "/admin/reserves",
						icon: CheckSquare,
						title: "Reserves",
					},
					{
						href: "/admin/lloguers",
						icon: LocalMall,
						title: "Lloguers",
					},
					{},
					{
						href: "/admin/calendariEAV",
						icon: Calendar,
						title: "Calendari EAV",
					},
					{
						href: "/admin/entradesEAV",
						icon: CheckSquare,
						title: "Entrades EAV",
					},
			  ]
			: [
					{
						href: "/admin/",
						icon: Home,
						title: "Inici",
					},
					{
						href: "/admin/calendari",
						icon: Calendar,
						title: "Calendari",
					},
					{
						href: "/admin/reserves",
						icon: CheckSquare,
						title: "Reserves",
					},
					{
						href: "/admin/lloguers",
						icon: LocalMall,
						title: "Lloguers",
					},
					{},
					{
						href: "/admin/calendariEAV",
						icon: Calendar,
						title: "Calendari EAV",
					},
					{
						href: "/admin/entradesEAV",
						icon: CheckSquare,
						title: "Entrades EAV",
					},
			  ];

	const content = (
		<Box
			height="100%"
			display="flex"
			flexDirection="column"
			className={classes.menu}
		>
			<Box p={2}>
				<RouterLink to="/">
					<Icon
						alt="Logo Pèndol Guies"
						className={classes.logo}
						width={200}
					/>
				</RouterLink>
				<List>
					{items?.map((item) =>
						item.href ? (
							<NavBarItem
								href={item.href}
								key={item.title}
								title={item.title}
								icon={item.icon}
								onClose={onMobileClose}
							/>
						) : (
							<Divider
								style={{
									marginTop: 5,
									marginBottom: 5,
									borderColor:
										theme.palette.background.color + "40",
								}}
							/>
						)
					)}
				</List>
			</Box>
			<Box flexGrow={1} />
		</Box>
	);

	return (
		<div>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer
					anchor="left"
					classes={{ paper: classes.desktopDrawer }}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</div>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
